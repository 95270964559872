<template>
    <div class="gst-search-hotel-modal px-4 py-6">
        <div class="gst-search-hotel-modal__header d-flex flex-row align-center pb-2">
            <ChipNew class="gst-search-hotel-modal__chip mr-3" />
            <h4>{{ $t('title') }}</h4>
        </div>
        <p class="mb-6">
            {{ $t('subtitle') }}
        </p>
        <template v-if="!$vuetify.breakpoint.mdAndUp">
            <LocationSearchStoreDropDown
                :value="location"
                :label="$t('labels.location')"
                disabled
                class="gst-search-hotel-modal__location"
                @click="openSearchExpandedModal" />
        </template>
        <template v-else>
            <div>
                <LocationSearchStoreDropDown
                    ref="location"
                    :value="location"
                    class="gst-search-hotel-modal__location mb-2"
                    :label="$t('labels.location')"
                    :error-messages="_extractValidationsMessages( 'location' )"
                    @input="onLocationChangeDo" />
                <DatePickerTooltipDisabledDates
                    v-model="dates"
                    full-width
                    flat
                    range
                    color="primary"
                    :min="minBookingStartDate"
                    class="gst-search-hotel-modal__picker"
                    no-title />
            </div>
            <div
                class="d-flex flex-column flex-grow-1"
                :class="[$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'pr-3']">
                <h5 class="gst-rooms-tabs-filters__guests-title">
                    {{ $t( '_common:terms.guest_plural') }}
                </h5>
                <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                    <p>{{ $t( '_common:terms.adult_plural' ) }}</p>
                    <div>
                        <NumericStep :min="1" :max="99" :value="adultsCount" :icons-attrs="iconsAttrs" @input="updateAdultsCount" />
                    </div>
                </div>
                <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                    <p>{{ $t( '_common:terms.room_plural' ) }}</p>
                    <div>
                        <NumericStep :min="1" :max="adultsCount" :value="roomsCount" :icons-attrs="iconsAttrs" @input="updateRoomsCount" />
                    </div>
                </div>
                <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                    <div class="d-flex align-start flex-column">
                        <p>
                            {{ $t( '_common:terms.children' ) }}
                        </p>
                        <span class="children-ages-label">{{ $t('labels.children') }}</span>
                    </div>
                    <div>
                        <NumericStep :min="0" :max="99" :value="childrenCount" :icons-attrs="iconsAttrs" @input="updateChildrenCount" />
                    </div>
                </div>
                <div v-if="childrenCount" class="children-ages-container d-flex flex-wrap">
                    <div v-for="(_, idx) in childrenCount" :key="idx" ref="childAgeContainer" class="col-3 px-0 py-1">
                        <div class="child-age">
                            <p>{{ $t( '_common:terms.childAge', { index: idx + 1 } ) }}</p>
                            <BaseFormSelect
                                :id="`select.childAges${idx}`"
                                v-model="childAges[idx]"
                                v-ripple
                                :options="childAgeOptions"
                                :clearable="false"
                                data-test-id="select.children"
                                class="gst-base-form-select-variant-2 u-width-100 u-height-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="gst-search-hotel-modal__footer mt-6">
                <BaseButton
                    :placeholder="$t( 'buttons.find' )"
                    class="gst-search-hotel-modal__button u-width-100"
                    data-test-id="searchHotelButtonApply"
                    color="primary"
                    @click="save" />
                <BaseButton
                    text
                    color="primary"
                    class="text-capitalize"
                    :ripple="false"
                    @click="reset">
                    {{ $t('_common:buttons.reset') }}
                </BaseButton>
            </div>
        </template>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import NumericStep from '@core/shared/components/input/NumericStep';
    import ChipNew from '@tenant/app/components/chips/ChipNew';
    import LocationSearchStoreDropDown from '@tenant/app/components/misc/LocationSearchStoreDropDown/LocationSearchStoreDropDown';
    import DatePickerTooltipDisabledDates from '@tenant/app/components/date/DatePickerTooltipDisabledDates.vue';
    import BaseFormSelect from '@tenants/ticketmaster/app/components/inputs/BaseFormSelect.vue';
    import FilterMixin from './AddHotelReservation/FilterMixin';

    export default ( {
        name: 'SearchHotelModal',
        components: {
            BaseButton,
            ChipNew,
            LocationSearchStoreDropDown,
            DatePickerTooltipDisabledDates,
            NumericStep,
            BaseFormSelect
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'modules.hotel.features.addHotelReservation.searchHotelModal'
        },
        mixins: [
            FilterMixin
        ],
        methods: {
            openSearchExpandedModal( ) {
                this.$emit( 'close' );
                this.$modal.showBottom(
                    ( ) => import( './SearchHotelExpandedMobileModal' ),
                    null,
                    {
                        'content-class': 'v-dialog__height-90',
                        persistent: true,
                        scrollable: false
                    }
                );
            }
        },
        /**
         * @description
         * @param {any}
         */
        /**
         * @description
         * @param {any}
         */
        mounted( ) {
            if ( this.$refs.location ) {
                this.$refs.location.focus( );
            }
        }
    } );
</script>